<template>
<div class="main-container">
  <Header />

  <TopCarousel :sliders="slider.LikeUCreative" />

  <AboutSectionFour :data="likeucreative.about" :title="'¿Qué es LikeU Creative?'" />

  <!--Our clients section-->
  <!--<LatestProject
      :data="likeucreative.owrClients"
      :title="'Nuestros Clientes'"
    />-->

  <div class="swiper-slide latest-project-slider__single-slide">
    <div class="banner-image">
      <img style="width: 100%;padding: 0px;" src="/assets/img/banners/likeucreative/7.jpg" class="img-fluid" alt="thumbnail" />
    </div>
  </div>

  <!--Uses and Implementatios section-->
  <ProjectGridSlider :data="likeucreative.implementations" :title="'Nuestros servicios'" :height="'height220'" />

  <ProjectGridSlider :data="likeucreative.catalogue" :title="'Portafolio'" :height="'height90'" />

  <!--Advantage-->
  <div class="page-wrapper section-space--inner--60">
    <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="service-details">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h2 style="padding-bottom: 20px;">
                    Ventajas competitivas
                  </h2>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <img style="max-width: 90%;" src="/assets/img/banners/likeucreative/9.jpg" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="team-job__content-wrapper">
                    <div class="team-job__list-wrapper">
                      <div class="team-job__single">
                        <h3 class="title">
                          Equipo multidisciplinario
                        </h3>
                        <p class="text" style="text-align: justify;">
                          Contamos con un equipo multidisciplinario y
                          preparado para atender cualquier necesidad.
                        </p>
                      </div>
                      <div class="team-job__single">
                        <h3 class="title">
                          Herramientas tecnológicas
                        </h3>
                        <p class="text" style="text-align: justify;">
                          Les ofrecemos a todos nuestros clientes tecnologías
                          para el seguimiento de todas sus campañas de
                          marketing.
                        </p>
                      </div>
                      <div class="team-job__single">
                        <h3 class="title">
                          Networking directo en toda Latinoamérica
                        </h3>
                        <p class="text" style="text-align: justify;">
                          Nuestra amplia red de contactos nos permite
                          conectarnos y tener presencia en 154 grupos
                          internacionales.
                        </p>
                      </div>
                      <div class="team-job__single">
                        <h3 class="title">
                          Asesoramiento gratuito en clientes Tipo A
                        </h3>
                        <p class="text" style="text-align: justify;">
                          Te ofrecemos una asesoría incial sin costo
                          adicional.
                        </p>
                      </div>
                      <div class="team-job__single">
                        <h3 class="title">
                          Testimonios exitosos en más de 30 países
                        </h3>
                        <p class="text" style="text-align: justify;">
                          Nuestros clientes avalan nuestro servicio.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="swiper-slide latest-project-slider__single-slide">
    <div class="banner-image">
      <img style="width: 100%;padding: 0px;" src="/assets/img/banners/likeucreative/10.jpg" class="img-fluid" alt="thumbnail" />
    </div>
  </div>

  <CarouselPlanSection2 :title="'Planes de Contratación'" :subtitle="'Los costos varían según el uso que deseas darle'" :platform="20" :selectorText="'Selecciona el paquete de tu preferencia'" :plse_type="'creative'" />

  <!--<div class="breadcrumb-area bg-img" style="background-image: url(assets/img/banners/payforu/f3.jpg);width: -webkit-fill-available;height: 80px;">
    <div class="row">
      <div class="col-7">
        <h2 class="text-center" style="color:#fff;"></h2>
      </div>
      <div class="col-5">
        <a href="htts://malllikeu.com">
          <button class="btn ht-btn--round" style="background-color: #003d74;">
            Contratar
          </button>
        </a>
      </div>
    </div>
  </div>-->

  <div class="about-section section-space--inner--60" id="contact-form">
    <div class="container">
      <div class="row row-25">
        <div class="col-12">
          <h2 class="subtitle text-center" style="font-weight: bold;">
            Preguntas frecuentes
          </h2>
        </div>
        <div class="col-lg-6 col-12 mt--30" style="padding-top: 20px;">
          <div v-for="item in likeucreative.FAQ" :key="item.id">
            <Accordion :question="item.question" :answer="item.answer" />
          </div>
        </div>
        <div class="col-lg-6 col-12 mb-30">
          <ContactForm :platform="20" />
        </div>
      </div>
    </div>
  </div>

  <div class="about-section section-space--inner--20">
    <div class="container">
      <div class="row row-25">
        <div class="col-12">
          <h2 class="subtitle text-center" style="font-weight: bold;">
            Conoce nuestro Ecosistema Digital
          </h2>
        </div>
      </div>
    </div>
  </div>

  <Carousel2 :data="slider.ecosystem" />

  <BrandCarousel addClass="grey-bg" />

  <Footer />

  <OffCanvasMobileMenu />

  <!-- WhatsApp Bottom -->
  <div id="whatsapp">
    <a href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios." title="Escríbenos por Whastapp" data-tap="tooltip">
      <i class="fa fa-whatsapp" style="color: #ffffff;" aria-hidden="true"></i>
    </a>
  </div>

  <!-- back to top start -->
  <back-to-top class="scroll-top" bottom="100px" right="10px">
    <i class="ion-android-arrow-up"></i>
  </back-to-top>
  <!-- back to top end -->
</div>
</template>

<script>
import slider from "../data/slider.json";
import data from "../data/service.json";
import likeucreative from "../data/likeucreative.json";
import Header from "@/components/Header";
import AboutSectionFour from "../components/sections/AboutSectionFour";
import HeroTwo from "../components/sections/HeroTwo";
import Carousel1 from "../components/sections/Carousel1";
import TopCarousel from "../components/TopCarousel";
import Carousel2 from "../components/sections/Carousel2";
import ProjectGridSlider from "@/components/sections/ProjectGridSlider";
import Breadcrumb from "../components/Breadcrumb";
import TeamMember from "../components/sections/TeamMember";
import LatestProject from "../components/sections/LatestProject";
import Accordion from "../components/Accordion";
import ContactForm from "../components/ContactForm";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import CarouselPlanSection2 from "@/components/sections/CarouselPlanSection2";

export default {
  components: {
    Header,
    HeroTwo,
    LatestProject,
    Breadcrumb,
    Accordion,
    ContactForm,
    TopCarousel,
    ServiceDetailsMain,
    ProjectGridSlider,
    TeamMember,
    AboutSectionFour,
    ServiceDetailsSidebar,
    Carousel1,
    Carousel2,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CarouselPlanSection2
  },
  data() {
    return {
      config: {
        headers: {
          Authorization: ""
        }
      },
      data,
      slider,
      likeucreative,
      orderData: "",
      user_id: ""
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.breadcrumb-area {
  min-height: 0px;
}

.swiper-wrapper {
  padding: 0px !important;
}

.login-form {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}

.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}

#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }

  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
</style>
